import React from 'react';
import './hamburgermenuStyle.css';




const Logo =
<img src={require('../../assets/images/logo2.png')}  alt=" "/>;




function HamburgerMenu () {
  return (
      <div>
    <input type="checkbox" id="op"></input>
        <div className="lower">
            <label for="op">{ Logo }</label>
        </div>
        <div className="overlay overlay-hugeinc">
			<label for="op"></label> 
			<nav>
				<ul className="path">
					<li><a href="#">Home</a></li>
					<li><a href="#">About</a></li>
					<li><a href="#">Work</a></li>
					<li><a href="#">Clients</a></li>
					<li><a href="#">Contact</a></li>
				</ul>
			</nav>
</div>
</div>
  );
}

export default HamburgerMenu;