import React from "react";
import './MouseScroll.css';

function MouseScroll() {
  return (
    <div className="scroll-downs">
      <div className="mousey">
        <div className="scroller"></div>
      </div>
    </div>
  );
}

export default MouseScroll;
