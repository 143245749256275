import React, { Component } from 'react';

export class About extends Component {
    render() {
        return (
            <section className="cta-about">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="cta-about-image">
                                <img src={require("../../assets/images/cta-about.jpg")} alt="cta-image" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="cta-about-content ptb-120">
                                <div className="section-title">
                                    <span className="wow fadeInUp">About Us</span>
                                    <h2 className="wow fadeInDown">Helping you open windows of opportunity worldwide.</h2>
                                </div>
                                <p>RoiX is a digital agency with nearly two decades of experience in digital marketing and web design. RoiX  specializes in creating full-service marketing solutions that combine digital growth strategy, branding, web development and design, SEO, PPC, social media, and content creation.</p>

                                <p>RoiX designs, implements, and manages Google, Microsoft, Facebook, Amazon, and LinkedIn Advertising accounts for big and mid-market businesses, by focuses on providing quantifiable results to its clients.</p>

                                <a href="#" className="btn btn-primary wow fadeInUp">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;
