import React from 'react';
import '../assets/styles/homepage.css';
import HamburgerMenu from '../componenents/Hamburgermenu/HamburgermenuComponent';
import GooglePartners from '../componenents/Google-Partners/GooglePartners';
import MouseScroll from "../componenents/MouseScroll/MouseScroll";
import About from "../componenents/About/About";



function HomePage() {
  return (

    <div className="grid-container">
    <div className="shape-main-header"></div>
    <div className="logocircle"></div>
    <div className="logocircle2"></div>
    <div className="logoplace"><HamburgerMenu /></div>
    <div className="vignette-small">The best marketing doesn’t feel like marketing!</div>
    <div className="slider-title">THINKING OUT OF THE BOX</div>
    <div className="roix-motto"><div className="roix-motto-title">ROIX</div></div>
    <div className="slider-photo"></div>
    <div className="fillet-break"></div>
    <div className="cta-title"></div>
    <div className="first-page-break">
    <div className="scrollmouse">{ MouseScroll }</div>

    </div>
    <div className="session-2">
    <div className="nested-elem-google">
    <div className="google-partners-image"></div>
    <div className="google-partners-title"> Premier Google Partner | We are certified</div>
    </div>
    </div>
</div>

  );
}


export default HomePage;
