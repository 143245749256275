import React from 'react';
import HomePage from './pages/Homepage';




function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}


export default App;
